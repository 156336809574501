import { Col, Row } from "antd";
import { matchPath, useHistory } from "react-router-dom";
import { FC } from "react";

import "./companyProfile.less";
import { IRoute, paths, Routes } from "@router";
import Tabs from "@components/Tabs";
import { tabs } from "./config";
import { checkPermission } from "@/utils";
import { permissions } from "@/router";
import { useTypedSelector } from "@/hooks";
import { useState } from "react";

import CompanyRow from "../CompanyList/companyRow";
import { Button, Input } from "antd";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { useFetchCompaniesQuery } from "@services";
import { setSelectedCompanyInStorage } from "@/services/global-storage";
import { ICompany } from "@/types";

import "../CompanyList/apps.less";

interface ICompanyProfile {
  routes: IRoute[];
}

const ClientCompanyProfile: FC<ICompanyProfile> = ({ routes }) => {
  const { user } = useTypedSelector(state => state.auth);
  // const { company_id } = useParams<{ company_id: string }>();
  const history = useHistory();
  const match = matchPath(window.location.pathname, {
    path: paths.admin.users.companies.profile.business_units.regions,
  });
  const [
    page,
    // setPage
  ] = useState(1);
  // const selectedApp = useTypedSelector(state => state.selected_app);
  const [searchString, setSearchString] = useState<string>("");
  const [isCompanySelected, setIsCompanySelected] = useState<boolean>(false);
  const [selectedCompany, setselectedCompany] = useState<ICompany>();
  const { data: companiesData, isLoading: isFetching } =
    useFetchCompaniesQuery(page);
  const { data } = companiesData || {};

  const onPressNext = () => {
    setIsCompanySelected(true);
    history.push(
      `${paths.admin.users.companies.listing}/${selectedCompany?.id}`
    );
  };

  if (
    !isCompanySelected &&
    user?.role === "TOM_MASTER_USER" &&
    !user?.company?.id
  ) {
    return (
      <div className="companies__container">
        <div className="apps__main__heading">Companies</div>
        <div className="apps_description">
          Select or search company to continue
        </div>
        <Input
          className="companies__searchbar"
          type="search"
          placeholder=" Search Company"
          onChange={e => setSearchString(e.target.value.toLowerCase())}
          prefix={<SearchOutlined color="#435465" />}
        />
        <div className="company__list-container" id="company__list-container">
          {isFetching ? (
            <div>
              <LoadingOutlined className="spinner" />
            </div>
          ) : (
            <>
              {data
                .filter((comp: ICompany) =>
                  comp?.name?.toLowerCase().includes(searchString)
                )
                .map((comp: ICompany) => (
                  <CompanyRow
                    company={comp}
                    isSelected={comp.name === selectedCompany?.name}
                    onCompanySelect={app => {
                      setselectedCompany(app);
                      setSelectedCompanyInStorage(app);
                    }}
                    key={comp.id}
                  />
                ))}
            </>
          )}
        </div>
        <Button
          type="primary"
          htmlType="submit"
          className="login__btn"
          size="large"
          disabled={!selectedCompany}
          onClick={onPressNext}
        >
          Next
        </Button>
      </div>
    );
  }
  return (
    <div>
      {checkPermission([
        permissions.VIEW_BUSINESS_UNIT,
        permissions.VIEW_REGION,
      ]) ? (
        <div className="companyProfile">
          {match ? null : (
            <>
              <Row>
                <Col span={24}>
                  <div className="main-heading mb-16">Company details</div>
                </Col>
              </Row>
              {console.log(user?.company?.id, selectedCompany?.id)}
              <Tabs
                param=":company_id"
                route={true}
                paramValue={
                  user?.company?.id
                    ? `${user?.company?.id}`
                    : `${selectedCompany?.id}`
                }
                tabs={tabs}
              />
            </>
          )}
          <div className="companyProfile__content">
            <Routes routes={routes} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ClientCompanyProfile;
