import { Button as AntButton, Input } from "antd";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { ICompany } from "@/types";
import { setSelectedCompanyInStorage } from "@/services/global-storage";
import { useFetchCompaniesQuery } from "@services";
// import { useTypedSelector } from "@/hooks";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { paths } from "@/router";
import CompanyRow from "@/pages/CompanyList/companyRow";

const SubAdmin = () => {
  const history = useHistory();
  const [
    page,
    // setPage
  ] = useState(1);
  // const selectedApp = useTypedSelector(state => state.selected_app);
  const [searchString, setSearchString] = useState<string>("");
  const [isCompanySelected, setIsCompanySelected] = useState<boolean>(false);
  const [selectedCompany, setselectedCompany] = useState<ICompany>();
  const { data: companiesData, isLoading: isFetching } =
    useFetchCompaniesQuery(page);
  const { data } = companiesData || {};

  const onPressNext = () => {
    setIsCompanySelected(true);
    history.push(
      `${paths.admin.users.sub_admins.sub_admin}/list/${selectedCompany?.id}`
    );
  };

  if (!isCompanySelected) {
    return (
      <div className="companies__container">
        <div className="apps__main__heading">Companies</div>
        <div className="apps_description">
          Select or search company to continue
        </div>
        <Input
          className="companies__searchbar"
          type="search"
          placeholder=" Search Company"
          onChange={e => setSearchString(e.target.value.toLowerCase())}
          prefix={<SearchOutlined color="#435465" />}
        />
        <div className="company__list-container" id="company__list-container">
          {isFetching ? (
            <div>
              <LoadingOutlined className="spinner" />
            </div>
          ) : (
            <>
              {data
                .filter((comp: ICompany) =>
                  comp?.name?.toLowerCase().includes(searchString)
                )
                .map((comp: ICompany) => (
                  <CompanyRow
                    company={comp}
                    isSelected={comp.name === selectedCompany?.name}
                    onCompanySelect={app => {
                      setselectedCompany(app);
                      setSelectedCompanyInStorage(app);
                    }}
                    key={comp.id}
                  />
                ))}
            </>
          )}
        </div>
        <AntButton
          type="primary"
          htmlType="submit"
          className="login__btn"
          size="large"
          disabled={!selectedCompany}
          onClick={onPressNext}
        >
          Next
        </AntButton>
      </div>
    );
  }
};

export default SubAdmin;
