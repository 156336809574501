import { useState } from "react";

import CompanyRow from "./companyRow";
import { Button, Input } from "antd";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { useFetchCompaniesQuery } from "@services";
import { setSelectedCompanyInStorage } from "@/services/global-storage";
import { ICompany } from "@/types";

import "./apps.less";
import { useTypedSelector } from "@/hooks";

export interface CompanyListType {
  name: string;
  desc: string;
  continents: string;
  icon: string;
}

const Apps = () => {
  const [
    page,
    // setPage
  ] = useState(1);
  const selectedApp = useTypedSelector(state => state.selected_app);
  const [searchString, setSearchString] = useState<string>("");
  const [selectedCompany, setselectedCompany] = useState<ICompany>();
  const { data: companiesData, isLoading: isFetching } =
    useFetchCompaniesQuery(page);
  const { data } = companiesData || {};

  const onPressNext = () => {
    window.open(selectedApp.applicationLink, "_self");
  };

  return (
    <div className="companies__container">
      <div className="apps__main__heading">Companies</div>
      <div className="apps_description">
        Select or search company to continue
      </div>
      <Input
        className="companies__searchbar"
        type="search"
        placeholder=" Search Company"
        onChange={e => setSearchString(e.target.value.toLowerCase())}
        prefix={<SearchOutlined color="#435465" />}
      />
      <div className="company__list-container" id="company__list-container">
        {isFetching ? (
          <div>
            <LoadingOutlined className="spinner" />
          </div>
        ) : (
          <>
            {data
              .filter((comp: ICompany) =>
                comp?.name?.toLowerCase().includes(searchString)
              )
              .map((comp: ICompany) => (
                <CompanyRow
                  company={comp}
                  isSelected={comp.name === selectedCompany?.name}
                  onCompanySelect={app => {
                    setselectedCompany(app);
                    setSelectedCompanyInStorage(app);
                  }}
                  key={comp.id}
                />
              ))}
          </>
        )}
      </div>
      <Button
        type="primary"
        htmlType="submit"
        className="login__btn"
        size="large"
        disabled={!selectedCompany}
        onClick={onPressNext}
      >
        Next
      </Button>
    </div>
  );
};

export default Apps;
