import React from "react";
import { Layout as AntdLayout, Menu, Dropdown, Avatar, message } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import _ from "lodash";

import "./layout.less";
import { ReactComponent as Logo } from "@assets/images/logo.svg";
import { ReactComponent as Down } from "@assets/images/arrow-down.svg";
import { ReactComponent as Bell } from "@assets/images/bell.svg";
import { IConfig, client_config } from "./sidebar-config";
import profilePic from "@assets/images/avatar.png";
import { useTypedSelector } from "@hooks";

import {
  ErrorServices,
  loadRefreshToken,
  // useFetchCompanyJobGradesQuery,
  useLogoutMutation,
} from "@services";
import { checkPermission, isAdmin } from "@utils";
// import { ssoUrl } from "@/constants";

const ssoUrl = "";
const appsPageUrl = "/apps";

const { Header, Sider } = AntdLayout;

const Layout2 = ({ children }: { children: React.ReactNode }) => {
  const { user } = useTypedSelector(state => state.auth);
  const { id: company_id } = user?.company || {};
  // const { data: jobGradesData } = useFetchCompanyJobGradesQuery(
  //   { company_id },
  //   { skip: !!company_id === false }
  // );
  // const { data: jobGrades } = jobGradesData || {};
  // const { data: jobGrades } = {};
  const [isMenuVisible, setIsMenuVisible] = React.useState(false);
  const [onLogout] = useLogoutMutation();
  const { pathname } = useLocation();

  console.log(!isAdmin(user?.role!), { user });
  const config = client_config(
    [
      {
        grade: "",
        type: null,
        country_ids: [1],
        countries: [
          {
            id: 1,
            name: "",
          },
        ],
        id: 1,
        is_global: true,
      },
    ],
    company_id?.toString() || "1",
    `${user?.role}`
  );

  // const config = isAdmin(user?.role!)
  //   ? admin_config
  //   : client_config([{
  //     grade: "",
  //     type: null,
  //     country_ids: [1],
  //     countries: [{
  //       id: 1,
  //       name: ""
  //     }],
  //     id: 1,
  //     is_global: true,
  //   }], company_id?.toString() || "");
  // : client_config(jobGrades, company_id?.toString() || "");

  const handleLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    path: string | Array<string>,
    disabled?: boolean | undefined,
    fallbackMessage?: string | undefined
  ) => {
    if (Array.isArray(path)) {
      event.preventDefault();
      if (disabled) message.error(fallbackMessage);
    }
  };

  const getRoute = (path: string | Array<string>): string => {
    if (typeof path == "string") return path;
    else if (
      Array.isArray(path) &&
      path.includes(pathname) &&
      typeof pathname == "string"
    )
      return pathname;
    else return "";
  };

  const onLogoutFromServer = async () => {
    try {
      const refresh = loadRefreshToken();
      await onLogout({ refresh }).unwrap();
      window.open(ssoUrl, "_self"); // open SSO page after logout
    } catch (error) {
      ErrorServices(error);
    }
  };

  const openAppsPage = () => {
    window.open(appsPageUrl, "_self");
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={openAppsPage}>
        Apps
      </Menu.Item>
      <Menu.Item key="1" onClick={onLogoutFromServer}>
        Log out
      </Menu.Item>
    </Menu>
  );

  // if (
  //   Object.values(paths.admin.auth).some(path =>
  //     window.location.pathname.includes(path as string)
  //   )
  // )
  //   return <div>{children}</div>;

  return (
    <AntdLayout className="layout2__container">
      <AntdLayout>
        <Sider width={200} className="layout2__sider sider">
          <div className="sider__logo__container">
            <Logo />
          </div>
          {config?.map((config: IConfig, idx) => {
            return (
              <div className="sider__links__container" key={idx}>
                <NavLink
                  exact={true}
                  key={idx}
                  to={getRoute(config.path)}
                  className="sider__link"
                  activeClassName="sider__active"
                  onClick={event => {
                    handleLinkClick(
                      event,
                      config.path,
                      config?.disabled,
                      config?.fallbackMessage
                    );
                  }}
                >
                  <div
                    className={`sider__icon__container ${
                      config?.client && "sider__icon__container--client"
                    }`}
                  >
                    <config.icon className="sider__link__icon" />
                  </div>
                  {config.title}
                </NavLink>
                {!config?.disabled &&
                  config.sub?.map((subLink: any, i: number) =>
                    checkPermission(subLink?.permission) ? (
                      <NavLink
                        key={i}
                        to={subLink.path || ""}
                        className="sider__sub__link"
                        activeClassName="sider__active"
                        onClick={event => handleLinkClick(event, subLink.path)}
                      >
                        <div
                          className={`sider__icon__container ${
                            config?.client && "sider__icon__container--client"
                          }`}
                        >
                          <subLink.icon className="sider__link__icon" />
                        </div>
                        {subLink.title}
                      </NavLink>
                    ) : null
                  )}
              </div>
            );
          })}
        </Sider>
        <AntdLayout className="content__container">
          <Header className="layout2__header">
            <div className="header__notif__icon__container">
              <Bell className="header__notif__icon" />
            </div>
            <div className="header__divider"></div>
            <Dropdown
              overlay={menu}
              onVisibleChange={open => setIsMenuVisible(open)}
              visible={isMenuVisible}
              trigger={["click"]}
            >
              <div className="profile__menu__toggle">
                <Avatar size={32} src={profilePic} />
                <div className="profile__menu__text__container">
                  <span className="profile__menu__user__name">{`${user?.first_name} ${user?.last_name}`}</span>
                  <span className="profile__menu__user__role">
                    {_.replace(_.startCase(_.toLower(user?.role)), "_", " ")}
                  </span>
                </div>
                <div className="profile__menu__icon__container">
                  <Down className="profile__menu__icon" />
                </div>
              </div>
            </Dropdown>
          </Header>

          {/* <Breadcrumb className="layout2__breadcrumbs">
            {breadcrumbList?.map((breadcrumb: any) => (
              <Breadcrumb.Item key={breadcrumb}>{breadcrumb}</Breadcrumb.Item>
            ))}
          </Breadcrumb> */}

          <div
            className={
              pathname === "/app" || pathname === "/select-company"
                ? "layout__background-wrapper"
                : ""
            }
            style={{
              width: "calc(100vw - 252px)",
              height: "100vh",
              position: "absolute",
              padding: pathname !== "/app" ? "130px 24px" : "0",
              overflowY: "auto",
            }}
          >
            {children}
          </div>
        </AntdLayout>
      </AntdLayout>
    </AntdLayout>
  );
};

export default Layout2;
