import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppType } from "@/types";

const initialState: IAppType = {
  id: 0,
  name: "",
  desc: "",
  initials: "",
  applicationLink: "",
  disabled: false,
  key: "",
};

const selectedAppSlice = createSlice({
  name: "selected_app",
  initialState,
  reducers: {
    setSelectedAppInRedux: (state, selected_app: PayloadAction<IAppType>) =>
      selected_app.payload,
  },
});

export const { setSelectedAppInRedux } = selectedAppSlice.actions;
export default selectedAppSlice.reducer;
