// import { message } from "antd";
import { createSlice } from "@reduxjs/toolkit";

import { IAuthState } from "@types";
import { authApi, saveTokens } from "@services";

const initialState: IAuthState = {
  user: null,
  token: {
    access: null,
    refresh: null,
  },
  permissions: [],
  applications: [],
  is_one_time_password: false,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(authApi.endpoints.logout.matchPending, (state, action) => {
        state.user = null;
        // message.success(`User has been successfully logout`);
      })
      .addMatcher(
        authApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          if (payload?.data?.is_one_time_password) {
            state.is_one_time_password = payload?.data?.is_one_time_password;
            state.token.access = payload?.data?.token;
          } else {
            state.is_one_time_password = payload?.data?.is_one_time_password;
            state.user = payload?.data?.user;
            state.token = payload?.data?.token;
            state.applications = payload?.data?.applications;
            state.permissions = !payload?.data?.permissions?.length
              ? ["all"]
              : payload?.data?.permissions;
          }
        }
      )
      .addMatcher(
        authApi.endpoints.verify.matchFulfilled,
        (state, { payload }) => {
          if (payload?.data?.is_one_time_password) {
            state.is_one_time_password = payload?.data?.is_one_time_password;
            state.token.access = payload?.data?.token;
          } else {
            saveTokens(payload.data.token);
            state.is_one_time_password = payload?.data?.is_one_time_password;
            state.user = payload?.data?.user;
            state.token = payload?.data?.token;
            state.applications = payload?.data?.applications;
            state.permissions = !payload?.data?.permissions?.length
              ? ["all"]
              : payload?.data?.permissions;
          }
        }
      );
  },
});

export default slice.reducer;
