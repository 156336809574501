import { createApi } from "@reduxjs/toolkit/query/react";

import { tomService } from "./restService";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: tomService({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
  }),

  endpoints: builder => ({
    login: builder.mutation({
      query: ({ body }) => ({
        url: `/v2/auth/login/`,
        method: "POST",
        body,
      }),
    }),
    verify: builder.mutation({
      query: ({ companyId }) => ({
        url: `/v2/auth/verify-user/?application=SSO&company_id=${companyId}`,
        method: "GET",
      }),
    }),
    resetPassword: builder.mutation({
      query: body => ({
        url: "/auth/forgot-password/",
        method: "POST",
        body,
      }),
    }),
    changePassword: builder.mutation({
      query: body => ({
        url: "/auth/change-password/",
        method: "POST",
        body,
      }),
    }),
    logout: builder.mutation({
      query: body => ({
        url: "/auth/logout/",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useVerifyMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useLogoutMutation,
} = authApi;
