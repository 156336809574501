import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Typography, Form, Input, Button, Checkbox } from "antd";

import "@styles/auth.less";
import { paths } from "@router";
import {
  ErrorServices,
  loadRefreshToken,
  useLoginMutation,
  useLogoutMutation,
} from "@services";
import {
  removeUserDateFromSharedStorage,
  setUserDataInSharedStorage,
  removeSelectedCompanyInStorage,
} from "@/services/global-storage";
interface ILoginForm {
  email: string;
  password: string;
  remember: boolean;
}

const Login = () => {
  const history = useHistory();
  const [errorFields, setErrorFields] = useState([]);
  const [login, { isLoading }] = useLoginMutation();
  const [logout] = useLogoutMutation();
  const [form] = Form.useForm();

  useEffect(() => {
    // when SSO login page opens. logout user from SSO app
    removeUserDateFromSharedStorage();
    removeSelectedCompanyInStorage();
    const refresh = loadRefreshToken();
    logout({ refresh });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinishedFailed = (errorInfo: any) => {
    setErrorFields(errorInfo.errorFields);
  };

  const signIn = async (values: ILoginForm) => {
    setErrorFields([]);
    const { email, password } = values;
    try {
      const res = await login({
        body: {
          email,
          password,
        },
      }).unwrap();
      if (res?.data?.is_one_time_password) {
        history.push(paths.ssoRoute.auth.reset_password);
      } else {
        history.push(paths.ssoRoute.apps.apps);
        setUserDataInSharedStorage(res.data.token);
      }
    } catch (error: any) {
      ErrorServices(error);

      setErrorFields([{ errors: [error?.message], name: ["password"] }] as any);
      form.setFields([
        {
          name: "password",
          errors: [error?.message],
        },
      ]);
    }
  };

  const checkError = (name: string) =>
    errorFields?.some((x: any) => x.name.includes(name));

  return (
    <div className="auth__form__container">
      <Typography.Paragraph className="auth__form_title">
        Welcome to <span className="primary-color">Talent Accelerator</span>
      </Typography.Paragraph>
      <div className="auth__form__prompt">
        Login to your account to continue...
      </div>
      {/* FORM */}
      <Form
        name="login"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={signIn}
        onFinishFailed={onFinishedFailed}
        layout="vertical"
        form={form}
        className="auth__form"
      >
        <Form.Item
          className="form__item"
          validateTrigger="onSubmit"
          label={
            <label
              className={`${
                checkError("email") ? "error__label" : "input__label"
              } secondary-color`}
            >
              Email Address
            </label>
          }
          name="email"
          rules={[{ required: true, message: "Please enter your email!" }]}
        >
          <Input
            className="form__input"
            type="email"
            placeholder="Enter your email here..."
          />
        </Form.Item>

        <Form.Item
          className="form__item"
          validateTrigger="onSubmit"
          label={
            <div className="auth__password__label">
              <label
                className={`${
                  checkError("password") ? "error__label" : "input__label"
                } secondary-color`}
              >
                Password
              </label>
              <Link
                to={paths.ssoRoute.auth.forgot_password}
                className="auth__forgot__password link"
              >
                Forgot password?
              </Link>
            </div>
          }
          name="password"
          rules={[{ required: true, message: "Please enter your password!" }]}
        >
          <Input.Password
            className="form__input error__pwd"
            placeholder="Enter your password here..."
          />
        </Form.Item>

        <Form.Item
          name="remember"
          valuePropName="checked"
          className="auth__checkbox__item"
        >
          <Checkbox className="auth__checkbox">Remember me</Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{ span: 24 }}>
          <Button
            type="primary"
            htmlType="submit"
            className="login__btn"
            size="large"
          >
            {isLoading ? <LoadingOutlined className="spinner" /> : "Sign In"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
