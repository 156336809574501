import Cookies from "universal-cookie";
import { cookiesDomain } from "@/constants";
const cookies = new Cookies();

export const setUserDataInSharedStorage = (data: any) => {
  cookies.set("user_data", data, { path: "/", domain: cookiesDomain });
};

export const removeUserDateFromSharedStorage = () => {
  cookies.remove("user_data", { path: "/", domain: cookiesDomain });
};

export const setSelectedCompanyInStorage = (data: any) => {
  cookies.set("selected_company", data, { path: "/", domain: cookiesDomain });
};

export const removeSelectedCompanyInStorage = () => {
  cookies.remove("selected_company", { path: "/", domain: cookiesDomain });
};

export const setSelectedApplicationInCookies = (data: any) => {
  cookies.set("selected_application", data, {
    path: "/",
    domain: cookiesDomain,
  });
};
