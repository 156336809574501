import { ReactComponent as UsersIcon } from "@assets/images/users.svg";
import { ReactComponent as CompaniesIcon } from "@assets/images/companies.svg";
import { ReactComponent as DashboardGridIcon } from "@assets/images/dashboard-grid.svg";
import { paths } from "@router";
import { IJobGrade } from "@/types";

export interface IConfig {
  title: string;
  icon: any;
  path: string | Array<string>;
  routingPath?: string;
  sub?: Array<IConfig>;
  permission?: string | Array<string>;
  client?: boolean;
  disabled?: boolean;
  fallbackMessage?: string;
}

export const client_config = (
  jobGrades: IJobGrade[],
  company_id: string,
  user_role: string
): Array<IConfig> => [
  {
    title: "Apps",
    icon: DashboardGridIcon,
    path: paths.ssoRoute.apps.apps,
  },
  {
    title: "My Company",
    icon: CompaniesIcon,
    path:
      user_role === "TOM_MASTER_USER"
        ? paths.admin.users.companies.listing
        : paths.admin.users.companies.profile.details.replace(
            ":company_id",
            company_id
          ),
  },
  {
    title: "Sub-Admins",
    icon: UsersIcon,
    path:
      user_role === "TOM_MASTER_USER"
        ? paths.admin.users.sub_admins.sub_admin
        : paths.admin.users.sub_admins.listing.replace(
            ":company_id",
            company_id
          ),
  },
];
