import { ICompany } from "@/types";

import "./companyRow.less";

interface ICompanyRow {
  company: ICompany;
  isSelected: boolean;
  onCompanySelect: (company: ICompany) => void;
}

const CompanyRow = (props: ICompanyRow) => {
  const { company, isSelected, onCompanySelect } = props;
  const { name, country, large_logo_url } = company;
  return (
    <div
      className={`company__row ${isSelected ? "selected" : ""}`}
      onClick={() => onCompanySelect(company)}
    >
      <div className="company-icon">
        <img src={large_logo_url} alt="company-logo" />
      </div>
      <div className="country__info-container">
        <div className="app__name">{name}</div>
        <div className="app__desc">Some description</div>
      </div>
      <div className="country__continent">{country.name}</div>
    </div>
  );
};

export default CompanyRow;
