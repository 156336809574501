import { useEffect, useState, FC } from "react";
import { Switch, Checkbox } from "antd";
import { PermissionsTableModal } from "@types";
import "./style.less";

const PERMS = {
  businessUnits: "BUSINESS_UNIT",
  region: "REGION",
  grade: "JOB_GRADE",
  salaryRange: "SALARY_RANGE",
  allowances: "CASH_ALLOWANCE",
  payrollUpload: "INTERNAL_PAYROLL",
  marketDataUpload: "MARKET_DATA",
  offerCreation: "OFFER",
  shortTermIncentives: "COMPANY_STI",
  country: "COUNTRY",
  jobEvaluation: "JOB_EVALUATION",
};

const CREATE = {
  createBusinessUnits: "CREATE_BUSINESS_UNIT",
  createRegion: "CREATE_REGION",
  createGrade: "CREATE_JOB_GRADE",
  createSalaryRange: "CREATE_SALARY_RANGE",
  createAllowances: "CREATE_CASH_ALLOWANCE",
  createPayrollUpload: "CREATE_INTERNAL_PAYROLL",
  createMarketDataUpload: "CREATE_MARKET_DATA",
  createOfferCreation: "CREATE_OFFER",
  createShortTermIncentives: "CREATE_COMPANY_STI",
  createCountry: "CREATE_COUNTRY",
  createJobEvaluation: "CREATE_JOB_EVALUATION",
};

const VIEW = {
  viewBusinessUnits: "VIEW_BUSINESS_UNIT",
  viewRegion: "VIEW_REGION",
  viewGrade: "VIEW_JOB_GRADE",
  viewSalaryRange: "VIEW_SALARY_RANGE",
  viewAllowances: "VIEW_CASH_ALLOWANCE",
  viewPayrollUpload: "VIEW_INTERNAL_PAYROLL",
  viewMarketDataUpload: "VIEW_MARKET_DATA",
  viewOfferCreation: "VIEW_OFFER",
  viewShortTermIncentives: "VIEW_COMPANY_STI",
  viewCountry: "VIEW_COUNTRY",
  viewJobEvaluation: "VIEW_JOB_EVALUATION",
};

const UPDATE = {
  updateBusinessUnits: "UPDATE_BUSINESS_UNIT",
  updateRegion: "UPDATE_REGION",
  updateGrade: "UPDATE_JOB_GRADE",
  updateSalaryRange: "UPDATE_SALARY_RANGE",
  updateAllowances: "UPDATE_CASH_ALLOWANCE",
  updatePayrollUpload: "UPDATE_INTERNAL_PAYROLL",
  updateMarketDataUpload: "UPDATE_MARKET_DATA",
  updateOfferCreation: "UPDATE_OFFER",
  updateShortTermIncentives: "UPDATE_COMPANY_STI",
  updateCountry: "UPDATE_COUNTRY",
  updateJobEvaluation: "UPDATE_JOB_EVALUATION",
};

const DELETE = {
  deleteBusinessUnits: "DELETE_BUSINESS_UNIT",
  deleteRegion: "DELETE_REGION",
  deleteGrade: "DELETE_JOB_GRADE",
  deleteSalaryRange: "DELETE_SALARY_RANGE",
  deleteAllowances: "DELETE_CASH_ALLOWANCE",
  deletePayrollUpload: "DELETE_INTERNAL_PAYROLL",
  deleteMarketDataUpload: "DELETE_MARKET_DATA",
  deleteOfferCreation: "DELETE_OFFER",
  deleteShortTermIncentives: "DELETE_COMPANY_STI",
  deleteCountry: "DELETE_COUNTRY",
};

const PermissionsTable: FC<PermissionsTableModal> = ({
  permissionAPIArray,
  role,
  setCallback,
}) => {
  const [permissions, setPermissions] = useState<string[]>([]);

  useEffect(() => {
    if (permissionAPIArray?.length > 0) {
      setPermissions(permissionAPIArray);
    }
  }, [permissionAPIArray]);

  const handleChange = (e: any, perm: string) => {
    console.log(e, perm);
    const tempPermissions = [...permissions];
    if (e.target.checked) {
      tempPermissions.push(perm);
      setPermissions(tempPermissions);
    } else {
      tempPermissions.splice(tempPermissions.indexOf(perm), 1);
      setPermissions(tempPermissions);
    }
  };

  const handleSwitchChange = (e: any, perm: string, val: string) => {
    console.log(e, perm);
    const tempPermissions = [...permissions];
    if (e) {
      tempPermissions.push(perm);
      setPermissions(tempPermissions);
    } else {
      setPermissions(tempPermissions.filter(elm => !elm.includes(val)));
    }
  };

  useEffect(() => {
    console.log(permissions);
    setCallback(permissions);
    // eslint-disable-next-line
  }, [permissions]);

  return (
    <table className="per_table">
      <thead>
        <tr>
          <th className="per_table_th">All Permisions</th>
          <th className="per_table_th"></th>
          <th className="per_table_th">Create</th>
          <th className="per_table_th">View</th>
          <th className="per_table_th">Update</th>
          <th className="per_table_th">Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="per_table_td">
            <div>Business Units</div>
            <div className="helping_text">
              Some text to describe the above function
            </div>
          </td>
          <td className="per_table_td">
            <Switch
              disabled={role}
              checked={role || permissions.includes(VIEW.viewBusinessUnits)}
              onChange={e => {
                handleSwitchChange(
                  e,
                  VIEW.viewBusinessUnits,
                  PERMS.businessUnits
                );
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewBusinessUnits)}
              checked={role || permissions.includes(CREATE.createBusinessUnits)}
              onChange={e => {
                handleChange(e, CREATE.createBusinessUnits);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role}
              checked={role || permissions.includes(VIEW.viewBusinessUnits)}
              onChange={e => {
                handleSwitchChange(
                  e.target.checked,
                  VIEW.viewBusinessUnits,
                  PERMS.businessUnits
                );
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewBusinessUnits)}
              checked={role || permissions.includes(UPDATE.updateBusinessUnits)}
              onChange={e => {
                handleChange(e, UPDATE.updateBusinessUnits);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewBusinessUnits)}
              checked={role || permissions.includes(DELETE.deleteBusinessUnits)}
              onChange={e => {
                handleChange(e, DELETE.deleteBusinessUnits);
              }}
            />
          </td>
        </tr>
        <tr>
          <td className="per_table_td">
            <div>Region</div>
            <div className="helping_text">
              Some text to describe the above function
            </div>
          </td>
          <td className="per_table_td">
            <Switch
              disabled={role}
              checked={role || permissions.includes(VIEW.viewRegion)}
              onChange={e => {
                handleSwitchChange(e, VIEW.viewRegion, PERMS.region);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewRegion)}
              checked={role || permissions.includes(CREATE.createRegion)}
              onChange={e => {
                handleChange(e, CREATE.createRegion);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role}
              checked={role || permissions.includes(VIEW.viewRegion)}
              onChange={e => {
                handleSwitchChange(
                  e.target.checked,
                  VIEW.viewRegion,
                  PERMS.region
                );
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewRegion)}
              checked={role || permissions.includes(UPDATE.updateRegion)}
              onChange={e => {
                handleChange(e, UPDATE.updateRegion);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewRegion)}
              checked={role || permissions.includes(DELETE.deleteRegion)}
              onChange={e => {
                handleChange(e, DELETE.deleteRegion);
              }}
            />
          </td>
        </tr>
        <tr>
          <td className="per_table_td">
            <div>Country</div>
            <div className="helping_text">
              Some text to describe the above function
            </div>
          </td>
          <td className="per_table_td">
            <Switch
              disabled={role}
              checked={role || permissions.includes(VIEW.viewCountry)}
              onChange={e => {
                handleSwitchChange(e, VIEW.viewCountry, PERMS.country);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewCountry)}
              checked={role || permissions.includes(CREATE.createCountry)}
              onChange={e => {
                handleChange(e, CREATE.createCountry);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role}
              checked={role || permissions.includes(VIEW.viewCountry)}
              onChange={e => {
                handleSwitchChange(
                  e.target.checked,
                  VIEW.viewCountry,
                  PERMS.country
                );
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewCountry)}
              checked={role || permissions.includes(UPDATE.updateCountry)}
              onChange={e => {
                handleChange(e, UPDATE.updateCountry);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewCountry)}
              checked={role || permissions.includes(DELETE.deleteCountry)}
              onChange={e => {
                handleChange(e, DELETE.deleteCountry);
              }}
            />
          </td>
        </tr>
        <tr>
          <td className="per_table_td">
            <div>Job Evaluation</div>
            <div className="helping_text">
              Some text to describe the above function
            </div>
          </td>
          <td className="per_table_td">
            <Switch
              disabled={role}
              checked={role || permissions.includes(VIEW.viewJobEvaluation)}
              onChange={e => {
                handleSwitchChange(
                  e,
                  VIEW.viewJobEvaluation,
                  PERMS.jobEvaluation
                );
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewJobEvaluation)}
              checked={role || permissions.includes(CREATE.createJobEvaluation)}
              onChange={e => {
                handleChange(e, CREATE.createJobEvaluation);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role}
              checked={role || permissions.includes(VIEW.viewJobEvaluation)}
              onChange={e => {
                handleSwitchChange(
                  e.target.checked,
                  VIEW.viewJobEvaluation,
                  PERMS.jobEvaluation
                );
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewJobEvaluation)}
              checked={role || permissions.includes(UPDATE.updateJobEvaluation)}
              onChange={e => {
                handleChange(e, UPDATE.updateJobEvaluation);
              }}
            />
          </td>
          <td className="per_table_td"></td>
        </tr>
      </tbody>
    </table>
  );
};

export default PermissionsTable;
