import ForgotPassword from "@/pages/Auth/ForgotPassword";
import Login from "@/pages/Auth/Login";
import ResetPassword from "@/pages/Auth/ResetPassword";
import Apps from "@/pages/Apps";
import CompanyList from "@/pages/CompanyList";
import RedirectToApps from "@/components/RedirectToApp/redirectToApp";
import { paths, IRoute } from "@router";
import ClientCompanyProfile from "@/pages/CompanyProfile";
import ClientCompanyDetails from "@/pages/CompanyProfile/CompanyDetails";
import ClientBusinessUnits from "@/pages/CompanyProfile/BusinessUnits";
import ClientRegions from "@/pages/CompanyProfile/Regions";
import SubAdmins from "@/pages/SubAdmins";
import CreateSubAdmin from "@/pages/SubAdmins/CreateSubAdmin";
import ClientCreateCompany from "@/pages/CreateCompany";
import SubAdmin from "@/pages/SubAdmins/SubAdmin";

export const routeConfig: IRoute[] = [
  {
    path: paths.ssoRoute.auth.login,
    component: Login,
    key: "Login",
    isPrivate: false,
  },
  {
    path: paths.ssoRoute.auth.forgot_password,
    component: ForgotPassword,
    key: "Forgot Password",
    isPrivate: false,
  },
  {
    path: paths.ssoRoute.auth.reset_password,
    component: ResetPassword,
    key: "Reset Password",
    isPrivate: false,
  },
  {
    path: paths.ssoRoute.apps.apps,
    component: Apps,
    key: "Apps",
    isPrivate: true,
  },
  {
    path: paths.ssoRoute.company.select_company,
    component: CompanyList,
    key: "Select Company",
    isPrivate: true,
  },
  {
    path: paths.admin.users.sub_admins.sub_admin,
    component: SubAdmin,
    key: "SubAmin",
    exact: true,
    // breadcrumb: "Users / Sub-Admins",
    isPrivate: true,
  },
  {
    path: paths.admin.users.sub_admins.listing,
    component: SubAdmins,
    key: "SubAminList",
    exact: true,
    // breadcrumb: "Users / Sub-Admins",
    isPrivate: true,
  },
  {
    path: paths.admin.users.sub_admins.create,
    component: CreateSubAdmin,
    key: "SubAdminsCreate",
    exact: true,
    breadcrumb: " Create Sub Admin",
    isPrivate: true,
  },
  {
    path: paths.admin.users.sub_admins.edit,
    component: CreateSubAdmin,
    key: "SubAdminsEdit",
    exact: true,
    breadcrumb: " Create Sub Admin",
    isPrivate: true,
  },
  {
    path: paths.admin.users.companies.edit,
    component: ClientCreateCompany,
    key: "Edit Company",
    exact: true,
    breadcrumb: "Users / Company Details / Create company",
    isPrivate: true,
    isCompany: true,
  },
  {
    path: paths.admin.users.companies.listing,
    component: ClientCompanyProfile,
    key: "Companies Details",
    breadcrumb: "Users /  Company details",
    isPrivate: true,
    isCompany: true,
    //permission: permissions.VIEW_COMPANY_USER,
    routes: [
      {
        path: paths.admin.users.companies.profile.details,
        component: ClientCompanyDetails,
        key: "Companies Details",
        exact: true,
        breadcrumb: "Users /  Company details",
        isPrivate: true,
        isCompany: true,
        // permission: permissions.VIEW_COMPANY_USER,
      },
      {
        path: paths.admin.users.companies.profile.business_units.listing,
        component: ClientBusinessUnits,
        key: "Business Units",
        exact: true,
        breadcrumb: "Users / Business Units",
        isPrivate: true,
      },
      {
        path: paths.admin.users.companies.profile.regions,
        component: ClientRegions,
        key: "Regions",
        exact: true,
        breadcrumb: "Users / Regions",
        isPrivate: true,
        isCompany: true,
      },
    ],
  },
  {
    path: paths.admin.users.companies.profile.business_units.listing,
    component: ClientBusinessUnits,
    key: "Business Units",
    exact: true,
    breadcrumb: "Users / Business Units",
    isPrivate: true,
  },
  {
    path: paths.admin.users.companies.profile.regions,
    component: ClientRegions,
    key: "Regions",
    exact: true,
    breadcrumb: "Users / Regions",
    isPrivate: true,
  },
  {
    path: "/",
    component: RedirectToApps,
    key: "Login",
    isPrivate: false,
  },
];

export const admin_routeConfig: IRoute[] = [
  // {
  //   path: paths.admin.users.companies.listing,
  //   component: Companies,
  //   key: "Companies",
  //   exact: true,
  //   breadcrumb: "Users / Companies list",
  //   isPrivate: true,
  // },
  // {
  //   path: paths.admin.users.companies.create,
  //   component: CreateCompany,
  //   key: "Create New Company",
  //   exact: true,
  //   breadcrumb: "Users / Companies list / Create new company",
  //   isPrivate: true,
  // },
  // {
  //   path: paths.admin.users.companies.edit,
  //   component: CreateCompany,
  //   key: "Edit Company",
  //   exact: true,
  //   breadcrumb: "Users / Companies list / Edit company",
  //   isPrivate: true,
  // },
  // {
  //   path: paths.admin.users.companies.profile.details,
  //   component: CompanyProfile,
  //   key: "Companies",
  //   breadcrumb: "Users / Companies list",
  //   isPrivate: true,
  //   routes: [
  //     {
  //       path: paths.admin.users.companies.profile.details,
  //       component: CompanyDetails,
  //       key: "Companies Details",
  //       exact: true,
  //       breadcrumb: "Users / Companies list",
  //       isPrivate: true,
  //     }
  //   ],
  // },
  // {
  //   path: paths.admin.users.sub_admins.listing,
  //   component: SubAdminsList,
  //   key: "SubAminList",
  //   exact: true,
  //   breadcrumb: "Users / Sub-Admins",
  //   isPrivate: true,
  // },
  // {
  //   path: paths.admin.users.sub_admins.create,
  //   component: SubAdminsCreate,
  //   key: "SubAdminsCreate",
  //   exact: true,
  //   breadcrumb: "Users / Sub-Admins/ Create Sub Admin",
  //   isPrivate: true,
  // },
  // {
  //   path: paths.admin.users.sub_admins.edit,
  //   component: SubAdminsEdit,
  //   key: "SubAdminsEdit",
  //   exact: true,
  //   breadcrumb: "Users / Sub-Admins/ Create Sub Admin",
  //   isPrivate: true,
  // },
  // {
  //   path: paths.admin.users.sub_admins.listing,
  //   component: SubAdminsList,
  //   key: "List sub admins",
  //   breadcrumb: "Users / Sub admins",
  //   exact: true,
  //   isPrivate: true,
  // },
  // {
  //   path: paths.admin.users.sub_admins.create,
  //   component: SubAdminsCreate,
  //   key: "Add new sub admin",
  //   breadcrumb: "Users / Sub admins / Add new sub admin",
  //   isPrivate: true,
  // }
];

export const client_routeConfig: IRoute[] = [
  // {
  //   path: paths.client.sub_admins.listing,
  //   component: SubAdmins,
  //   key: "Sub Admins",
  //   breadcrumb: "Users / Sub Admins",
  //   isPrivate: true,
  //   isCompany: true,
  //   exact: true,
  //   permission: permissions.VIEW_COMPANY_USER,
  // },
  // {
  //   path: paths.client.sub_admins.create,
  //   component: CreateSubAdmin,
  //   key: "Create Sub Admins",
  //   breadcrumb: "Users / Sub Admins / Create Sub Admin",
  //   isPrivate: true,
  //   isCompany: true,
  //   permission: permissions.CREATE_COMPANY_USER,
  // },
  // {
  //   path: paths.client.sub_admins.edit,
  //   component: CreateSubAdmin,
  //   key: "Update Sub Admins",
  //   breadcrumb: "Users / Sub Admins / Update Sub Admin",
  //   isPrivate: true,
  //   isCompany: true,
  //   permission: permissions.UPDATE_COMPANY_USER,
  // },
  // {
  //   path: paths.client.companies.edit,
  //   component: ClientCreateCompany,
  //   key: "Edit Company",
  //   exact: true,
  //   breadcrumb: "Users / Company Details / Edit company",
  //   isPrivate: true,
  //   isCompany: true,
  //   permission: permissions.VIEW_COMPANY_USER,
  // },
  // {
  //   path: paths.client.companies.profile.details,
  //   component: ClientCompanyProfile,
  //   key: "Company Profile Details",
  //   breadcrumb: "Users / Company details",
  //   isPrivate: true,
  //   isCompany: true,
  //  // permission: permissions.VIEW_COMPANY_USER,
  //   routes: [
  //     {
  //       path: paths.client.companies.profile.details,
  //       component: ClientCompanyDetails,
  //       key: "Companies Details",
  //       exact: true,
  //       breadcrumb: "Users /  Company details",
  //       isPrivate: true,
  //       isCompany: true,
  //       //permission: permissions.VIEW_COMPANY_USER,
  //     }
  //   ],
  // }
];
