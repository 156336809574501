import "./appRow.less";

import { IAppType } from "@/types";

interface IAppRow {
  app: IAppType;
  isSelected: boolean;
  onAppSelect: (app: IAppType) => void;
}

const AppRow = (props: IAppRow) => {
  const { app, isSelected, onAppSelect } = props;
  const { name, desc, initials } = app;
  return (
    <div
      className={`app__row ${isSelected ? "selected" : ""} ${
        app.disabled ? "disabled" : ""
      }`}
      onClick={() => onAppSelect(app)}
    >
      <div className="app__initials">{initials}</div>
      <div>
        <div className="app__name">{name}</div>
        <div className="app__desc">{desc}</div>
      </div>
    </div>
  );
};

export default AppRow;
