import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "antd";

import {
  adminDashboardUrl,
  tomAppUrl,
  jeAppUrl,
  rbAppUrl,
  listTom,
  listLivePayBenchmarking,
} from "@/constants";
import { paths } from "@/router";
import { useVerifyMutation } from "@/services";
import { useTypedSelector } from "@/hooks";
import { setSelectedAppInRedux } from "@/store/selected_app";
import { IAppType } from "@/types";
import "./apps.less";
import AppRow from "./appRow";
import { setSelectedApplicationInCookies } from "@/services/global-storage";
interface IApps {}

const Apps = (props: IApps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedApp, setSelectedApp] = useState<IAppType>();
  const [verifyUser] = useVerifyMutation();
  const userApplications = useTypedSelector(state => state.auth.applications);
  const companyId =
    useTypedSelector(state => state.auth.user?.company?.id) || 0;
  const isCompanyUser = useTypedSelector(
    state => state.auth.user?.is_company_user
  );

  useEffect(() => {
    verifyUser({ companyId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasAppPermission = (appId: number): boolean => {
    return isCompanyUser
      ? userApplications.some(app => app.id === appId)
      : true;
  };
  const appsList: IAppType[] = [
    ...(isCompanyUser
      ? []
      : [
          {
            id: 0,
            name: "Dashboard",
            desc: "Admin Dashboard",
            initials: "D",
            disabled: false,
            applicationLink: adminDashboardUrl,
            key: "ADMIN_PANEL",
          },
        ]),
    ...(listTom
      ? [
          {
            id: 1,
            name: "TOM",
            desc: "TOM Application",
            initials: "T",
            disabled: !hasAppPermission(1),
            applicationLink: tomAppUrl,
            key: "TOM",
          },
        ]
      : []),
    {
      id: 2,
      name: "Job Evaluation",
      desc: "Job Evaluation Application",
      initials: "JE",
      disabled: !hasAppPermission(2),
      applicationLink: jeAppUrl,
      key: "JE",
    },
    ...(listLivePayBenchmarking
      ? [
          {
            id: 3,
            name: "Live Pay Benchmarking",
            desc: "Reporting & Benchmark Application",
            initials: "RB",
            disabled: !hasAppPermission(3),
            applicationLink: rbAppUrl,
            key: "LIVE_PAY_BENCHMARKING_TOOL",
          },
        ]
      : []),
  ];

  const onClickNext = () => {
    if (isCompanyUser || selectedApp?.id === 0)
      window.open(selectedApp?.applicationLink!, "_self");
    else {
      history.push(paths.ssoRoute.company.select_company);
    }
  };
  const onAppSelect = (app: IAppType) => {
    setSelectedApp(app);
    setSelectedApplicationInCookies(app.key);
    dispatch(setSelectedAppInRedux(app));
  };
  return (
    <div className="apps__container">
      <div className="apps__main__heading">Apps</div>
      <div className="apps_description">Select App to continue</div>
      <div className="app__list-container">
        {appsList.map(app => (
          <AppRow
            app={app}
            isSelected={app.name === selectedApp?.name}
            onAppSelect={onAppSelect}
            key={app.name}
          />
        ))}
      </div>
      <Button
        type="primary"
        htmlType="submit"
        className="login__btn"
        size="large"
        disabled={!selectedApp}
        onClick={onClickNext}
      >
        Next
      </Button>
    </div>
  );
};

export default Apps;
