import { useState } from "react";
import { Col, message, Row } from "antd";
import { useHistory, useParams } from "react-router-dom";

import "./subAdmins.less";
import { getColumns } from "./config";
import Button from "@components/Button";
import Table from "@components/Table";
import {
  ErrorServices,
  useFetchCompanyUsersQuery,
  useUpdateCompanyUserStatusMutation,
} from "@services";
import { useTypedSelector } from "@hooks";
import { paths, permissions } from "@router";
import { checkPermission } from "@utils";

const SubAdmins = () => {
  const history = useHistory();
  const params: { company_id: string } = useParams();
  const paramsCompanyId = params.company_id;
  const [page, setPage] = useState(1);
  const { user } = useTypedSelector(state => state.auth);
  const { id: company_id } = user?.company || { id: paramsCompanyId };
  const { data, isLoading } = useFetchCompanyUsersQuery({ company_id, page });
  const { data: users, pagination } = data || {};
  const [updateCompanyUserStatus] = useUpdateCompanyUserStatusMutation();

  const updateSubAdmin = (id: number) =>
    history.push(`/sub-admins/edit/${id}/company/${company_id}`);

  const updateStatus = async (
    user_id: number,
    name: string,
    is_active: boolean
  ) => {
    try {
      await updateCompanyUserStatus({
        company_id,
        user_id,
        is_active,
      }).unwrap();
      message.success(`${name} is ${is_active ? "active" : "inactive"}`);
    } catch (error) {
      ErrorServices(error);
    }
  };

  const columns = getColumns({ updateStatus, id: user?.id!, updateSubAdmin });

  return (
    <div className="sub-admins">
      <Row className="mb-20">
        <Col span={16}>
          <div className="main-heading">Sub-Admins</div>
        </Col>
        <Col className="align-end" span={8}>
          {checkPermission(permissions.CREATE_COMPANY_USER) && (
            <Button
              variant="add"
              onClick={() =>
                history.push(
                  `${paths.admin.users.sub_admins.sub_admin}/create/${company_id}`
                )
              }
            >
              Create sub-admin
            </Button>
          )}
        </Col>
      </Row>
      <Table
        data={users}
        isLoading={isLoading}
        columns={columns}
        pagination={true}
        count={pagination?.count}
        onChangePage={setPage}
        page={page}
      />
    </div>
  );
};

export default SubAdmins;
